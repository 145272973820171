// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    & .overlay {
        display: grid;
        grid-template-rows: 1fr 6rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
        align-items: center;
        justify-content: center;

        & .content {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 5rem;
            max-width: 1100px;
            margin-top: 100px; /* Equal to header height */
            padding: 0 var(--padding-right) 0 var(--padding-left);
            color: #fff;

            & .primary-color {
                color: var(--color-primary);
            }

            & h1 {
                font-size: var(--font-size-banner-h1);
                font-weight: var(--font-weight-banner-h1);
                line-height: var(--line-height-banner-h1);
            }

            &-link {
                justify-self: start;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;

                & span {
                    margin-right: 10px;
                }
            }

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledIntroductionContainer = styled.div`
    display: grid;
    justify-items: center;

    & .content {
        display: grid;
        grid-template-rows: repeat(4, min-content);
        grid-row-gap: 5rem;
        max-width: 1100px;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);

        & .primary-color {
            color: var(--color-primary);
        }
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & h2 {
            margin-top: -4rem;
            font-weight: var(--font-weight-body-h2);
            font-size: var(--font-size-body-h2);
            line-height: var(--line-height-body-h2);
        }

        & p {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
            line-height: var(--line-height-body-p);
        }

        &-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        &-link:link,
        &-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;

const StyledOurFeatures = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }

    & .feature {
        width: 50vw;
        height: 50vw;

        @media only screen and (max-width: 800px) {
            width: 100vw;
            height: 100vw;

            &.content {
                height: auto;
            }
        }
    }

    & .feature.content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: var(--padding-right) var(--padding-right) var(--padding-left) var(--padding-left);
        background-color: var(--color-background-2);

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        & .content-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        & .content-link:link,
        & .content-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        & .content-link:hover,
        & .content-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        & .content-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;

const StyledFeatureImage = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 800px) {
        &.feature-image--2 {
            grid-row: 4 / 5;
        }

        &.feature-image--4 {
            grid-row: 8 / 9;
        }
    }
`;


// Create component
const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/index/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webOntwerpImage: file(relativePath: { eq: "index/Web-ontwerp.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webwinkelImage: file(relativePath: { eq: "index/Webwinkel.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            webApplicatiesImage: file(relativePath: { eq: "index/Web-applicaties.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            onlineAdverterenImage: file(relativePath: { eq: "index/Online-ads-editted.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Handle scrolling down to below banner
    const handleScrollDown = () => {
        // Get banner height
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        
        // Scroll to place underneath banner
        window.scrollTo({
            top: bannerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Home" />
            <Layout withBanner={true}>
                <StyledBanner
                    className="banner"
                    image={data.bannerImage}
                >
                    <div className="overlay">
                        <div className="content">
                            <h1><span className="primary-color">Boumedia</span> works together with big and small companies to optimize there digital presence.</h1>
                            <Link to="/our-services" className="content-link">
                                <span>Check out our services</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </Link>
                        </div>

                        <div className="scroll-down-button" onClick={handleScrollDown}>
                            <FontAwesomeIcon icon={faArrowDown} className="icon" />
                        </div>
                    </div>
                </StyledBanner>

                <StyledIntroductionContainer>
                    <div className="content">
                        <h1>Currently we have customers in the Netherlands, Cura&ccedil;ao, Australia, and South Africa.</h1>

                        <h2>We help our customers to move forward in this digital age.</h2>

                        <p>We at <span className="primary-color">boumedia</span> specialize in web design, online stores, web applications, and online advertising, digital marketing, content writing, top notch security, privacy, and optimal hosting. We use a wide variety of technologies depending on what application it is used for. We know that every customer is unique and has a different taste in design, therefore we adapt to this and give the customer different options to choose from. Therefore, every product that we produce is unique.</p>

                        <Link to="/our-services" className="content-link">Check out our services</Link>
                    </div>
                </StyledIntroductionContainer>

                <StyledOurFeatures>
                    <div className="feature content">
                        <h1>Web Design</h1>

                        <p className="feature__summary">We are always up-to-date with the newest web design trends and technologies in order to present the best suitable option for our customers. We focus on making your website user friendly and therefore focussing on the needs of your website visitors. We can offer you different options of web designs and giving you insight in the knowledge that we build over the past decade. In this way we add your personal taste to your website.</p>

                        <Link to="/our-services#web-design" className="content-link">Read more</Link>
                    </div>

                    <StyledFeatureImage
                        className="feature image feature-image--1"
                        image={data.webOntwerpImage}
                    ></StyledFeatureImage>

                    <StyledFeatureImage
                        className="feature image feature-image--2"
                        image={data.webwinkelImage}
                    ></StyledFeatureImage>

                    <div className="feature content">
                        <h1>Online Stores</h1>

                        <p className="feature__summary">With over a decade of experience in setting up online stores we know how to make your products more presentable and convert visitors into paying customers. We are a one stop shop and can therefore also help with online marketing and advertising to boost your digital presence and boost your sales. We can help with cross-selling, email marketing, Google Ads, social media marketing and much more.</p>

                        <Link to="/our-services#online-stores" className="content-link">Read more</Link>
                    </div>

                    <div className="feature content">
                        <h1>Web Applications</h1>

                        <p className="feature__summary">Web applications can make your life easier. We can analyze processes in your company and create efficient web applications that can help you streamline these processes. You can also make your workplace paperless with the use of a custom designed web application. Would you be interested in finding out how a web application can be beneficial for your company? Request a consultation for free where we analyze your business processes and identify workable solutions. Contact us today.</p>

                        <Link to="/our-services#web-applications" className="content-link">Read more</Link>
                    </div>

                    <StyledFeatureImage
                        className="feature image feature-image--3"
                        image={data.webApplicatiesImage}
                    ></StyledFeatureImage>

                    <StyledFeatureImage
                        className="feature image feature-image--4"
                        image={data.onlineAdverterenImage}
                    ></StyledFeatureImage>

                    <div className="feature content">
                        <h1>Online Advertising</h1>

                        <p className="feature__summary">We can either completely take over your digital marketing or we can help you out wherever you need. We talormade our offers based on your needs. Contact us today to find out what we can offer you in the digital marketing and advertising space. We can offer any solution for your budget.</p>

                        <Link to="/our-services#online-advertising" className="content-link">Read more</Link>
                    </div>
                </StyledOurFeatures>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default IndexPage;